import { useEffect, useState } from "react";
import { Button } from "@material-ui/core"
import { Box, Typography, Link } from "@mui/material"
import { useHistory, useLocation } from 'react-router-dom';
import { useMsal } from '@azure/msal-react';
import { loginRequest } from "../../config/auth";

const LoginPage = () => {
    const { instance } = useMsal();
    const account = instance.getActiveAccount();
    const history = useHistory();
    const [error, setError] = useState(null)
    const location = useLocation<{ from: string }>();

    /**
     * Handles the login process using MSAL's loginPopup method.
     * If successful, sets the active account and redirects the user to their intended destination.
     * If the login fails, the error message is stored in the `error` state.
     */
    const handleLogin = async()=>{
        await instance.loginPopup(loginRequest)
        .then(response => {
          instance.setActiveAccount(response.account);
          history.replace(location.state?.from || '/');
        })
        .catch(error => {
          setError(error.message)
        });
       }
    useEffect(() => {
       if(account){
        history.replace(location.state?.from || '/')
       }

      }, [history, location.state, account]);

    return(
        <Box sx={{width:"100%", height:"100vh", display:"flex", justifyContent:"center", flexDirection:"column", alignItems:"center"}}>
            <Box sx={{display:"flex", justifyContent:"center", flexDirection:"column", alignItems:"center",  border:1, borderColor: '#D6B66D', borderRadius:10, p:10}}>
            <img src="/favicon.svg" alt="Planning Poker Tool" className='HeaderIcon' width="100" height="100" />
            <Typography variant="h6">HGC Technologies</Typography>
            <Typography variant="subtitle1">Poker Planning</Typography>
            <Button onClick={handleLogin} variant="contained" color="primary" style={{color:"#fff", borderRadius:"2em", marginTop:10, maxWidth:220, width:"100%"}}> Login</Button>
            {error && <Typography sx={{pt:3}} variant="caption" color="red">{error}</Typography>}
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'end', pb:5 }}>
                <Link href="/termsandconditions"  color='#2f2f30' sx={{cursor:"pointer"}} fontSize={12}>Terms & conditions</Link>
                <span style={{marginLeft:5, marginRight:5}}>-</span>
                <Link href="/confidentialityAgreement" color='#2f2f30' sx={{cursor:"pointer"}} fontSize={12}>Confidentiality Agreement</Link>
              </Box>
        </Box>
    )
}

export default LoginPage;