import React, { useState, useEffect } from "react"
import { CssBaseline } from '@material-ui/core';
import { StylesProvider, ThemeProvider } from '@material-ui/core/styles';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { MsalProvider } from '@azure/msal-react';
import CookieConsent, { resetCookieConsentValue } from 'react-cookie-consent';
import { Toolbar } from './components/Toolbar/Toolbar';
import DeleteOldGames from './pages/DeleteOldGames/DeleteOldGames';
import { GamePage } from './pages/GamePage/GamePage';
import HomePage from './pages/HomePage/HomePage';
import JoinPage from './pages/JoinPage/JoinPage';
import TermsAndConditions from "./pages/Terms&ConditionsPage/Terms&ConditionsPage";
import ConfidentialityPolicy from "./pages/ConfidentialityPage/ConfidentialityPage"
import { useMsal } from '@azure/msal-react';
import { theme } from './service/theme';
import { AboutPage } from './pages/AboutPage/AboutPage';
import { ExamplesPage } from './pages/ExamplesPage/ExamplesPage';
import { GuidePage } from './pages/GuidePage/GuidePage';
import ProtectedRoute from './components/ProtectedRoutes/ProtectedRoute';
import LoginPage from './pages/LoginPage/LoginPage';
import { msalInstance } from './config/auth';

function App() {
 
  useEffect(()=>{
    resetCookieConsentValue("consentGiven")
  })

  return (
    <div className='LightTheme'>
      <MsalProvider instance={msalInstance}>
      <ThemeProvider theme={theme}>
        <StylesProvider injectFirst>
          <CssBaseline />
          <CookieConsent
        location="bottom"
        buttonText="I accept"
        cookieName="consentGiven"
        style={{ background: "#2B373B" }}
        buttonStyle={{ color: "#333232", fontSize: "13px", backgroundColor: "#D6B66D", borderRadius: "2em", borderWidth: 0 }}
        expires={150}
      >
        This website uses cookies to enhance the user experience.
      </CookieConsent>
          <Router>
            <Toolbar />
            <Switch>
              <Route path='/login' component={LoginPage} />
              <Route path='/termsandconditions' component={TermsAndConditions} />
              <Route path='/confidentialityAgreement' component={ConfidentialityPolicy} />
              <ProtectedRoute path='/game/:id' component={GamePage} />
              <ProtectedRoute path='/delete-old-games' component={DeleteOldGames} />
              <ProtectedRoute path='/join/:id' component={JoinPage} />
              <ProtectedRoute path='/about-planning-poker' component={AboutPage} />
              <ProtectedRoute path='/examples' component={ExamplesPage} />
              <ProtectedRoute path='/guide' component={GuidePage} />
              <ProtectedRoute exact path='/*' component={HomePage} />
            </Switch>
          </Router>
        </StylesProvider>
      </ThemeProvider></MsalProvider>
    </div>
  );
}

export default App;
