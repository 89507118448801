import 'firebase/analytics';
import { initializeApp } from 'firebase/app';
import 'firebase/firestore';
import { getFirestore, collection, addDoc, doc, setDoc, getDoc, getDocs, onSnapshot, updateDoc, deleteDoc, query, where } from 'firebase/firestore';
import { getAnalytics } from 'firebase/analytics';
import {  } from "firebase/firestore"; 
import { Game } from '../types/game';
import { Player } from '../types/player';
const firebaseConfig = {
  apiKey: "AIzaSyCRSthkHbahq183QCiCrzJlbqxaEdi0K74",
  authDomain: "poker-planning-433019.firebaseapp.com",
  projectId: "poker-planning-433019",
  storageBucket: "poker-planning-433019.appspot.com",
  messagingSenderId: "655065038834",
  appId: "1:655065038834:web:bbacbcbede6c46d0a1f56b",
  measurementId: "G-MDXQDNW2YY"
};



// Initialize Firebase
const app = initializeApp(firebaseConfig);
const gamesCollectionName = 'games';
const playersCollectionName = 'players';
const db = getFirestore(app);
const analytics = getAnalytics(app);

/**
 * Adds a game to the Firestore database.
 *
 * @param {string} gameId - The ID of the game to be added.
 * @param {any} data - The data associated with the game.
 * @returns {Promise<boolean>} A promise that resolves to `true` when the game is successfully added.
 */
export const addGameToStore = async (gameId: string, data: any) => {
  try {
    const docRef = doc(db, gamesCollectionName, gameId);
    await setDoc(docRef, data);
    console.log('Document successfully written!');
    return true;
  } catch (error) {
    console.error('Error writing document: ', error);
    return false;
  }
};

/**
 * Retrieves a game from the Firestore database by its ID.
 *
 * @param {string} id - The ID of the game to retrieve.
 * @returns {Promise<Game | undefined>} A promise that resolves to the game data or `undefined` if the game does not exist.
 */
export const getGameFromStore = async (id: string): Promise<Game | undefined> => {
  try {
    const docRef = doc(db, gamesCollectionName, id);
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      return docSnap.data() as Game;
    } else {
      console.log('No such document!');
      return undefined;
    }
  } catch (error) {
    console.error('Error getting document:', error);
    return undefined;
  }
};

/**
 * Retrieves the list of players associated with a specific game from the Firestore database.
 *
 * @param {string} gameId - The ID of the game.
 * @returns {Promise<Player[]>} A promise that resolves to an array of `Player` objects.
 */
export const getPlayersFromStore = async (gameId: string): Promise<Player[]> => {
  try {
    const playersCollectionRef = collection(db, gamesCollectionName, gameId, playersCollectionName);
    const querySnapshot = await getDocs(playersCollectionRef);

    const players: Player[] = querySnapshot.docs.map((doc) => doc.data() as Player);
    return players;
  } catch (error) {
    console.error('Error getting players:', error);
    return [];
  }
};

/**
 * Retrieves a specific player associated with a game from the Firestore database.
 *
 * @param {string} gameId - The ID of the game.
 * @param {string} playerId - The ID of the player to retrieve.
 * @returns {Promise<Player | undefined>} A promise that resolves to the player data or `undefined` if the player does not exist.
 */
export const getPlayerFromStore = async (gameId: string, playerId: string): Promise<Player | undefined> => {
  try {
    const playerDocRef = doc(db, gamesCollectionName, gameId, playersCollectionName, playerId);
    const docSnap = await getDoc(playerDocRef);

    if (docSnap.exists()) {
      return docSnap.data() as Player;
    } else {
      console.log('No such document!');
      return undefined;
    }
  } catch (error) {
    console.error('Error getting player:', error);
    return undefined;
  }
};

/**
 * Streams real-time updates for a specific game's data from the Firestore database.
 *
 * @param {string} id - The ID of the game.
 * @returns {firebase.firestore.DocumentReference} A Firestore document reference to the game.
 */
export const streamData = (id: string) => {
  const gameDocRef = doc(db, gamesCollectionName, id);
 

  return gameDocRef; // Return the unsubscribe function
};

/**
 * Streams real-time updates for the players of a specific game from the Firestore database.
 *
 * @param {string} id - The ID of the game.
 * @returns {firebase.firestore.CollectionReference} A Firestore collection reference to the players in the game.
 */
export const streamPlayersFromStore = (id: string) => {
  const playersCollectionRef = collection(db, gamesCollectionName, id, playersCollectionName);



  return playersCollectionRef; // Return the unsubscribe function
};

/**
 * Updates a game's data in the Firestore database.
 *
 * @param {string} gameId - The ID of the game to update.
 * @param {any} data - The new data to update the game with.
 * @returns {Promise<boolean>} A promise that resolves to `true` when the game is successfully updated.
 */
export const updateGameDataInStore = async (gameId: string, data: any): Promise<boolean> => {
  try {
    const gameDocRef = doc(db, gamesCollectionName, gameId);
    await updateDoc(gameDocRef, data);
    return true;
  } catch (error) {
    console.error('Error updating game data:', error);
    return false;
  }
};

/**
 * Adds a player to a specific game in the Firestore database.
 *
 * @param {string} gameId - The ID of the game.
 * @param {Player} player - The player data to be added.
 * @returns {Promise<boolean>} A promise that resolves to `true` when the player is successfully added.
 */
export const addPlayerToGameInStore = async (gameId: string, player: Player) => {
  try {
    const playerDocRef = doc(collection(db, gamesCollectionName, gameId, playersCollectionName), player.id);
    await setDoc(playerDocRef, player);
    return true;
  } catch (error) {
    console.error('Error adding player to game:', error);
    return false;
  }
};

/**
 * Removes a player from a specific game in the Firestore database.
 *
 * @param {string} gameId - The ID of the game.
 * @param {string} playerId - The ID of the player to remove.
 * @returns {Promise<boolean>} A promise that resolves to `true` when the player is successfully removed.
 */
export const removePlayerFromGameInStore = async (gameId: string, playerId: string) => {
  try {
    const playerDocRef = doc(collection(db, gamesCollectionName, gameId, playersCollectionName), playerId);
    await deleteDoc(playerDocRef);
    return true;
  } catch (error) {
    console.error('Error removing player from game:', error);
    return false;
  }
};

/**
 * Updates a player's data in a specific game in the Firestore database.
 *
 * @param {string} gameId - The ID of the game.
 * @param {Player} player - The player data to update.
 * @returns {Promise<boolean>} A promise that resolves to `true` when the player is successfully updated.
 */
export const updatePlayerInStore = async (gameId: string, player: Player) => {
  try {
    const playerDocRef = doc(collection(db, gamesCollectionName, gameId, playersCollectionName), player.id);
    //@ts-ignore
    await updateDoc(playerDocRef, player);
    return true;
  } catch (error) {
    console.error('Error updating player:', error);
    return false;
  }
};

/**
 * Removes a game and its associated players from the Firestore database.
 *
 * @param {string} gameId - The ID of the game to remove.
 * @returns {Promise<boolean>} A promise that resolves to `true` when the game and its players are successfully removed.
 */
export const removeGameFromStore = async (gameId: string) => {
  try {
    // Reference to the game document
    const gameDocRef = doc(db, gamesCollectionName, gameId);

    // Reference to the players collection within the game
    const playersCollectionRef = collection(db, gamesCollectionName, gameId, playersCollectionName);

    // Retrieve all players in the collection
    const playersSnapshot = await getDocs(playersCollectionRef);

    // Delete each player document
    const deletePromises = playersSnapshot.docs.map((playerDoc) => deleteDoc(playerDoc.ref));
    await Promise.all(deletePromises);

    // Delete the game document
    await deleteDoc(gameDocRef);

    return true;
  } catch (error) {
    console.error('Error removing game and its players:', error);
    return false;
  }
};

/**
 * Removes old games from the Firestore database that are older than a specified number of months.
 *
 * @returns {Promise<boolean>} A promise that resolves to `true` when old games are successfully removed.
 */
export const removeOldGameFromStore = async () => {
  try {
    const monthsToDelete = 6;
    const dateObj = new Date();
    const requiredDate = new Date(dateObj.setMonth(dateObj.getMonth() - monthsToDelete));

    // Query games older than the specified date
    const gamesCollectionRef = collection(db, gamesCollectionName);
    const oldGamesQuery = query(gamesCollectionRef, where('createdAt', '<', requiredDate));
    const gamesSnapshot = await getDocs(oldGamesQuery);

    console.log('Games length', gamesSnapshot.docs.length);

    if (gamesSnapshot.docs.length > 0) {
      for (const gameDoc of gamesSnapshot.docs) {
        console.log('Deleting:', gameDoc.data().name);

        // Query players in the game
        const playersCollectionRef = collection(db, gamesCollectionName, gameDoc.id, playersCollectionName);
        const playersSnapshot = await getDocs(playersCollectionRef);

        // Delete each player document
        const playerDeletePromises = playersSnapshot.docs.map((playerDoc) => deleteDoc(playerDoc.ref));
        await Promise.all(playerDeletePromises);

        // Delete the game document
        await deleteDoc(gameDoc.ref);
        console.log('Game deleted');
      }
    }

    return true;
  } catch (error) {
    console.error('Error removing old games:', error);
    return false;
  }
};
