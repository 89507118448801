import React from 'react';
import { Typography, Box, Container, Divider } from '@mui/material';
import { format } from 'date-fns';

const ConfidentialityPolicy = () => {
  return (
    <Container>
    <Box p={3}>
      {/* Main Title */}
      <Typography variant="h4" gutterBottom>
        Politique de Confidentialité
      </Typography>

      {/* Subtitle: Document Info */}
      <Typography variant="subtitle1" gutterBottom>
        Numéro de Politique: POL-PRIV-001 | Version: 1.0
      </Typography>
      <Typography variant="subtitle1" gutterBottom paddingBottom={5}>
        Date d’entrée en vigueur:  {format(new Date(), 'MMMM d, yyyy')}
      </Typography>

      {/* Section 1: Objectif */}
      <Typography variant="h5" gutterBottom>
        1. Objectif
      </Typography>
      <Typography variant="body1" gutterBottom>
        Cette politique de confidentialité décrit l’engagement de HGC Technologies à protéger les informations personnelles partagées via notre site web wearehgc.com et nos services associés.
      </Typography>
<Divider sx={{paddingTop:3, marginBottom:3}}/>
      {/* Section 2: Portée */}
      <Typography variant="h5" gutterBottom >
        2. Portée
      </Typography>
      <Typography variant="body1" gutterBottom>
        Cette politique s’applique à tous les employés, contractants et tiers qui traitent des informations personnelles au nom de HGC Technologies, ainsi qu’aux utilisateurs de notre site web et de nos services.
      </Typography>
      <Divider sx={{paddingTop:3, marginBottom:3}}/>
      {/* Section 3: Responsabilité */}
      <Typography variant="h5" gutterBottom>
        3. Responsabilité
      </Typography>
      <Typography variant="body1" gutterBottom>
        <strong>Antoine Arbour (DPO)</strong> - Responsable de la conformité aux réglementations sur la protection des données et de la supervision de la mise en œuvre de cette politique.
      </Typography>
      <Typography variant="body1" gutterBottom>
        <strong>Tous les employés</strong> - Doivent comprendre et respecter cette politique de confidentialité ainsi que les procédures pertinentes relatives à la gestion des données.
      </Typography>
      <Divider sx={{paddingTop:3, marginBottom:3}}/>
      {/* Section 4: Informations Collectées */}
      <Typography variant="h5" gutterBottom>
        4. Informations Que Nous Collectons
      </Typography>
      <Typography variant="body1" gutterBottom>
        Nous collectons des informations de base telles que le nom et l'adresse électronique via des formulaires, ainsi que des informations non personnelles (adresses IP, types de navigateurs) via des cookies et des outils d’analyse.
      </Typography>
      <Divider sx={{paddingTop:3, marginBottom:3}}/>
      {/* Section 5: Utilisation des Informations */}
      <Typography variant="h5" gutterBottom>
        5. Utilisation des Informations
      </Typography>
      <Typography variant="body1" gutterBottom>
        Nous utilisons ces informations pour améliorer nos services, répondre aux demandes des utilisateurs et assurer la sécurité du site web.
      </Typography>
      <Divider sx={{paddingTop:3, marginBottom:3}}/>
      {/* Section 6: Partage des Informations */}
      <Typography variant="h5" gutterBottom>
        6. Partage des Informations
      </Typography>
      <Typography variant="body1" gutterBottom>
        Nous ne vendons pas les informations personnelles, sauf pour respecter des obligations légales ou fournir des services par le biais de partenaires de confiance.
      </Typography>
      <Divider sx={{paddingTop:3, marginBottom:3}}/>
      {/* Section 7: Cookies */}
      <Typography variant="h5" gutterBottom>
        7. Cookies
      </Typography>
      <Typography variant="body1" gutterBottom>
        Les cookies sont utilisés pour améliorer l'expérience utilisateur. Les utilisateurs peuvent contrôler les paramètres des cookies via leur navigateur.
      </Typography>
      <Divider sx={{paddingTop:3, marginBottom:3}}/>
      {/* Section 8: Sécurité des Données */}
      <Typography variant="h5" gutterBottom>
        8. Sécurité des Données
      </Typography>
      <Typography variant="body1" gutterBottom>
        Nous employons des mesures de sécurité conformes aux standards de l’industrie pour protéger les données personnelles, bien qu’aucun service en ligne ne soit totalement sécurisé.
      </Typography>
      <Divider sx={{paddingTop:3, marginBottom:3}}/>
      {/* Section 9: Vos Droits */}
      <Typography variant="h5" gutterBottom>
        9. Vos Droits
      </Typography>
      <Typography variant="body1" gutterBottom>
        En fonction de votre emplacement, vous pouvez avoir des droits d'accès, de rectification ou de suppression des données personnelles. Contactez-nous à privacy@wearehgc.com pour exercer ces droits.
      </Typography>
      <Divider sx={{paddingTop:3, marginBottom:3}}/>
      {/* Section 10: Conformité et Application */}
      <Typography variant="h5" gutterBottom>
        10. Conformité et Application
      </Typography>
      <Typography variant="body1" gutterBottom>
        Le non-respect de cette politique peut entraîner des mesures disciplinaires pour les employés ou la résiliation de contrats avec les prestataires de services tiers. HGC Technologies se conforme aux réglementations de protection des données applicables, telles que RGPD.
      </Typography>
      <Divider sx={{paddingTop:3, marginBottom:3}}/>
      {/* Section 11: Révision */}
      <Typography variant="h5" gutterBottom>
        11. Révision
      </Typography>
      <Typography variant="body1" gutterBottom>
        Cette politique sera révisée annuellement ou en cas de changements significatifs dans les lois applicables ou les pratiques de HGC Technologies.
      </Typography>
      <Divider sx={{paddingTop:3, marginBottom:3}}/>
      {/* Section 12: Contact */}
      <Typography variant="h5" gutterBottom>
        12. Contactez-Nous
      </Typography>
      <Typography variant="body1" gutterBottom>
        HGC Technologies Inc.<br />
        Email: contact@wearehgc.com
      </Typography>
    </Box></Container>
  );
};

export default ConfidentialityPolicy;
