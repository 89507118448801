import React from 'react';
import { Typography, Box, Container, Divider } from '@mui/material';
import { format } from 'date-fns';

const TermsAndConditions = () => {
  return (
    <Container>
    <Box p={3}>
      <Typography variant="h4" gutterBottom>
        Terms and Conditions
      </Typography>

      <Typography variant="subtitle1" gutterBottom>
        Effective Date: {format(new Date(), 'MMMM d, yyyy')}
      </Typography>

      <Typography variant="body1" gutterBottom>
        Welcome to wearehgc.com. <br /> By accessing or using our website, you agree to the following terms and conditions:
      </Typography>

      <Typography variant="h5" gutterBottom >
        1. Website Use
      </Typography>
      <Typography variant="body1" gutterBottom>
        The content and information provided on this site are for general informational purposes only. We do not guarantee the accuracy, completeness, or timeliness of the content, and we reserve the right to modify or update the content at any time.
      </Typography>
      <Divider sx={{paddingTop:3, marginBottom:3}}/>
      <Typography variant="h5" gutterBottom >
        2. Intellectual Property
      </Typography>
      <Typography variant="body1" gutterBottom>
        All content on this website, including text, graphics, logos, and images, is the property of HGC Technologies Inc. or its content providers. Unauthorized use, reproduction, or distribution of any material is prohibited.
      </Typography>
      <Divider sx={{paddingTop:3, marginBottom:3}}/>
      <Typography variant="h5" gutterBottom>
        3. Limitations of Liability
      </Typography>
      <Typography variant="body1" gutterBottom>
        HGC Technologies Inc. is not liable for any direct, indirect, incidental, or consequential damages arising from your use of or inability to use the website.
      </Typography>
      <Divider sx={{paddingTop:3, marginBottom:3}}/>
      <Typography variant="h5" gutterBottom>
        4. Third-Party Links
      </Typography>
      <Typography variant="body1" gutterBottom>
        Our website may contain links to external sites. We are not responsible for the content or privacy practices of these third-party sites.
      </Typography>
      <Divider sx={{paddingTop:3, marginBottom:3}}/>
      <Typography variant="h5" gutterBottom >
        5. User Conduct
      </Typography>
      <Typography variant="body1" gutterBottom>
        You agree not to use the website for any unlawful activities or in ways that may harm the website's functionality. You also agree not to attempt to gain unauthorized access to the website or our systems.
      </Typography>
      <Divider sx={{paddingTop:3, marginBottom:3}}/>
      <Typography variant="h5" gutterBottom>
        6. Governing Law
      </Typography>
      <Typography variant="body1" gutterBottom>
        These terms are governed by and construed in accordance with the laws of [Insert Jurisdiction]. Any disputes arising out of the use of this website will be subject to the exclusive jurisdiction of the courts in [Insert Jurisdiction].
      </Typography>
      <Divider sx={{paddingTop:3, marginBottom:3}}/>
      <Typography variant="h5" gutterBottom>
        7. Changes to These Terms
      </Typography>
      <Typography variant="body1" gutterBottom>
        We reserve the right to modify these terms at any time. Continued use of the website implies acceptance of the updated terms.
      </Typography>
      <Divider sx={{paddingTop:3, marginBottom:3}}/>
      <Typography variant="h5" gutterBottom>
        8. Customer Contracts
      </Typography>
      <Typography variant="body1" gutterBottom >
        For customers who sign up for our services, separate contractual terms apply. These terms and conditions do not replace any specific agreements made with HGC Technologies Inc.
      </Typography>
    <Divider sx={{paddingTop:3, marginBottom:3}}/>
      <Typography variant="h5" gutterBottom>
        9. Contact Us
      </Typography>
      <Typography variant="body1" gutterBottom paddingBottom={5}>
        If you have any questions about these terms, please contact us at:
      </Typography>

      <Typography variant="caption" gutterBottom>
        HGC Technologies Inc.<br />
        Email: contact@wearehgc.com
      </Typography>
    </Box></Container>
  );
};

export default TermsAndConditions;
